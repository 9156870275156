import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import {
  AppBar,
  Badge,
  Box,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  Fab,
  Grid,
  IconButton,
  Link,
  Paper,
  Toolbar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useMousetrap } from "@mrii/react-mousetrap-hook";
import Menu from "./Menu";
import ConvenientSnackbar from "./ConvenientSnackbar";
import AppBarTitle from "./AppBarTitle";
import MainPlot from "./MainPlot";
import UserPlot from "./UserPlot";
import { UserContext } from "./UserProvider";
import EntryDialog from "./EntryDialog";
import SigIn from "./SignIn";
import AddUser from "./AddUser";
import UsersTable from "./UsersTable";
import PendingUsersTable from "./PendingUsersTable";
import firebase from "firebase/compat/app";
import BodyText from "./BodyText";
import WantToJoin from "./WantToJoin";
import ForgotPassword from "./ForgotPassword";
import logo from "./logo75.png";
import motivations from "./motivations.json";

const drawerWidth = 240;

const Pages = {
  DASHBOARD: 1,
  USERS_TABLE: 2,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  logoContainer: {
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  logo: {
    height: "calc(10px + 2vmin)",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  paperBody: {
    padding: theme.spacing(3),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fab: {
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  welcomeRoot: {
    display: "flex",
    alignItems: "center",
    margin: "16px 0",
  },
  welcomeText: {
    fontSize: "2em",
    fontWeight: "bold",
    paddingRight: "10px",
  },
  randomPhrase: {
    fontSize: "1.5em",
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  // Show/hide views.
  const [openMenu, setOpenMenu] = useState(false);
  const [showPage, setShowPage] = useState(Pages.DASHBOARD);
  const [showEntryDialog, setShowEntryDialog] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showWantToJoin, setShowWantToJoin] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState({
    show: false,
  });
  const [pendingBadgeCount, setPendingBadgeCount] = useState(0);

  // Function to pick a random phrase from the motivations array
  const getRandomPhrase = () => {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 0);
    const diff = now - startOfYear;
    const dayOfYear = Math.floor(diff / (1000 * 60 * 60 * 24));
    const index = dayOfYear % motivations.motivating_phrases.length;
    return motivations.motivating_phrases[index];
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection("pendingUsers")
      .onSnapshot((snap) => {
        setPendingBadgeCount(snap.size);
      });
  }, [showSignIn]);

  useMousetrap(["a"], () => {
    if (showPage === Pages.DASHBOARD && !showEntryDialog) {
      setShowEntryDialog(true);
    }
  });

  return (
    <UserContext.Consumer>
      {(user) => (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="absolute"
            className={clsx(classes.appBar, openMenu && classes.appBarShift)}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpenMenu(true)}
                className={clsx(
                  classes.menuButton,
                  openMenu && classes.menuButtonHidden
                )}
              >
                {!!user && user.isAdmin ? (
                  <Badge badgeContent={pendingBadgeCount} color="secondary">
                    <MenuIcon />
                  </Badge>
                ) : (
                  <MenuIcon />
                )}
              </IconButton>
              <div className={classes.logoContainer}>
                <img src={logo} alt="Logo" className={classes.logo} />
              </div>
              <AppBarTitle>The TI-Method</AppBarTitle>
              {!!user ? (
                <Link
                  color="inherit"
                  style={{ textDecoration: 'none' }}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    firebase.auth().signOut();
                    setShowPage(Pages.DASHBOARD);
                  }}
                >
                  Sign out
                </Link>
              ) : (
                ""
              )}
              <IconButton
                edge="end"
                color="inherit"
                onClick={() =>
                  !user ? setShowSignIn(true) : setShowPage(Pages.DASHBOARD)
                }
              >
                <AccountCircleIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            variant={"temporary"}
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !openMenu && classes.drawerPaperClose
              ),
            }}
            open={openMenu}
            onClose={() => {
              setOpenMenu(false);
            }}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={() => setOpenMenu(false)}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <Menu
              showDashboard={() => {
                setShowPage(Pages.DASHBOARD);
              }}
              showUsers={() => {
                setShowPage(Pages.USERS_TABLE);
              }}
              showSnackbar={setShowSnackbar}
              showUsersMenuOption={!!user && user.isAdmin}
              closeOnInteraction={true}
              closeMenu={() => setOpenMenu(false)}
              pendingBadgeCount={pendingBadgeCount}
            />
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={3}>
                {/* Plots */}
                {showPage === Pages.DASHBOARD && (
                  <React.Fragment>
                    {!!user && user.name && (
                      <Grid item xs={12}>
                        <div className={classes.welcomeRoot}>
                          <h1 className={classes.welcomeText}>
                            Welcome {user.name}!{" "}
                          </h1>
                          <p className={classes.randomPhrase}>
                            {getRandomPhrase()}
                          </p>
                        </div>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Paper className={classes.paper}>
                        <MainPlot noLimit={false} />
                      </Paper>
                    </Grid>
                    {!!user && (
                      <Grid item xs={12}>
                        <Paper className={classes.paper}>
                          <UserPlot user={user.email} />
                        </Paper>
                      </Grid>
                    )}
                  </React.Fragment>
                )}
                {/* UsersTable */}
                {!!user && showPage === Pages.USERS_TABLE && (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Paper className={classes.paper}>
                        <UsersTable showSnackbar={setShowSnackbar} />
                      </Paper>
                    </Grid>
                  </React.Fragment>
                )}
                {/* PendingUsersTable */}
                {!!user && showPage === Pages.USERS_TABLE && (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Paper className={classes.paper}>
                        <PendingUsersTable showSnackbar={setShowSnackbar} />
                      </Paper>
                    </Grid>
                  </React.Fragment>
                )}
                {/* BodyText */}
                {!user && showPage === Pages.DASHBOARD && (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Paper className={classes.paperBody}>
                        <BodyText
                          showWantToJoin={() => {
                            setShowWantToJoin(true);
                          }}
                        />
                      </Paper>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Box pt={4}></Box>
              {/* Snackbar */}
              <ConvenientSnackbar
                show={showSnackbar.show}
                success={showSnackbar.success}
                message={showSnackbar.message}
                duration={showSnackbar.duration}
                setShowSnackbar={setShowSnackbar}
              />
            </Container>
            {/* FAB */}
            <Fab
              color="primary"
              aria-label="add"
              className={classes.fab}
              style={{position: 'fixed'}}
              onClick={() => {
                if (!!user && user.isAdmin && showPage === Pages.USERS_TABLE) {
                  setShowAddUser(true);
                } else if (!!user) {
                  setShowEntryDialog(true);
                } else {
                  setShowSignIn(true);
                }
              }}
            >
              <AddIcon />
            </Fab>
            {/* Entry Dialog */}
            <EntryDialog
              open={showEntryDialog && !!user}
              user={user ? user.email : null}
              close={() => {
                setShowEntryDialog(false);
              }}
            />
            {/* Sign In Dialog */}
            <SigIn
              open={showSignIn}
              close={() => {
                setShowSignIn(false);
              }}
              showWantToJoin={() => {
                setShowWantToJoin(true);
              }}
              showForgotPassword={() => {
                setShowForgotPassword(true);
              }}
            />
            {/* Add user Dialog */}
            <AddUser
              open={showAddUser}
              close={() => {
                setShowAddUser(false);
              }}
              showSnackbar={setShowSnackbar}
            />
            {/* Want To Join Dialog */}
            <WantToJoin
              open={showWantToJoin}
              close={() => {
                setShowWantToJoin(false);
              }}
              showSnackbar={setShowSnackbar}
            />
            {/* Forgot Password Dialog */}
            <ForgotPassword
              open={showForgotPassword}
              close={() => {
                setShowForgotPassword(false);
              }}
              showSnackbar={setShowSnackbar}
            />
          </main>
        </div>
      )}
    </UserContext.Consumer>
  );
}
