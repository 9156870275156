import { parse, format, differenceInDays, addDays } from "date-fns";

class DateFunctions {
  static isSecondHalfOfYear = new Date().getMonth() >= 6; // Jan = 0, Dec = 11
  static startDate = format(
    new Date(),
    DateFunctions.isSecondHalfOfYear ? "yyyy-07-01" : "yyyy-01-01"
  );
  static endDate = format(
    new Date(),
    DateFunctions.isSecondHalfOfYear ? "yyyy-12-31" : "yyyy-06-30"
  );

  static normSum = (date) =>
    (differenceInDays(
      date,
      parse(DateFunctions.startDate, "yyyy-MM-dd", new Date())
    ) +
      1) *
    50;

  static normSumForDate = (dateStr) => {
    return DateFunctions.normSum(parse(dateStr, "yyyy-MM-dd", new Date()));
  };

  static inHalfYear = (date) => {
    return date >= DateFunctions.startDate && date < DateFunctions.endDate;
  };

  static getRangeToNow = () => {
    var endDate = new Date();
    var currentDate = parse(DateFunctions.startDate, "yyyy-MM-dd", new Date());
    var range = [];
    while (currentDate <= endDate) {
      range.push(format(currentDate, "yyyy-MM-dd"));
      currentDate = addDays(currentDate, 1);
    }
    return range;
  };
}

export default DateFunctions;
