import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { format } from "date-fns";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function WantToJoin(props) {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const [joinSent, setJoinSent] = useState(false);

  const validEmail = email.length > 5;
  const validName = name.length >= 4;
  const allValid = validEmail && validName;

  const join = () => {
    if (joinSent) {
      props.close();
    }

    setJoinSent(true);
    setTimeout(() => {
      setJoinSent(false);
    }, 30000);

    firebase
      .firestore()
      .collection("pendingUsers")
      .doc(email)
      .set({
        date: format(new Date(), "yyyy-MM-dd"),
        email: email,
        name: name,
        comment: comment,
      })
      .then(() => {
        props.showSnackbar({
          show: true,
          message: "Thanks for your interest! We will get back to you shortly.",
          duration: 10000,
          success: true,
        });
        props.close();
      })
      .catch((err) => {
        console.log(err);
        props.close();
      });
  };

  return [
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth="sm"
        open={props.open}
        onClose={() => {
          props.close();
        }}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Want To Join?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill in this form and we will get back to you when you have been
            accepted.
          </DialogContentText>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Your Name"
              name="name"
              error={!validName}
              helperText={!validName ? "Att least 4 characters." : ""}
              autoComplete="name"
              autoFocus
              onChange={(event) => {
                setName(event.target.value.trim());
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              error={!validEmail}
              helperText={!validEmail ? "Valid email is required." : ""}
              label="Your Email Address"
              name="email"
              autoComplete="email"
              onChange={(event) => {
                setEmail(event.target.value.trim());
              }}
            />
            <TextField
              variant="outlined"
              multiline
              rows={4}
              margin="normal"
              fullWidth
              id="comment"
              label="Comment on why you want to join"
              name="comment"
              onChange={(event) => {
                setComment(event.target.value.trim());
              }}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => join()}
              disabled={!allValid || joinSent}
            >
              Submit request to join
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>,
  ];
}

WantToJoin.defaultProps = {
  open: false,
  close: () => {},
  showSnackbar: () => {},
};

WantToJoin.propTypes = {
  open: PropTypes.any.isRequired,
  close: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
};
