import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const useStyles = makeStyles((theme) => ({
  errorText: {
    backgroundColor: "#ff8282",
    padding: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ForgotPassword(props) {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [requestPasswordReset, setRequestPasswordReset] = useState(false);
  const [lastError, setLastError] = useState("");

  const requestReset = () => {
    setLastError("");
    setRequestPasswordReset(true);
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setRequestPasswordReset(false);
        props.close();
        props.showSnackbar({
          show: true,
          message: "Password reset email sent.",
          duration: 5000,
          success: true,
        });
      })
      .catch((err) => {
        console.log(err);
        setRequestPasswordReset(false);
        setLastError(err.message);
      });
  };

  return [
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth="sm"
        open={props.open}
        onClose={() => {
          setLastError("");
          setRequestPasswordReset(false);
          props.close();
        }}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Forgot password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill in your email and a reset email link will be sent to you
            shortly.
          </DialogContentText>
          <form className={classes.form} noValidate>
            {lastError && (
              <Box fullWidth className={classes.errorText}>
                {lastError}
              </Box>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              disabled={requestPasswordReset}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => requestReset()}
              disabled={requestPasswordReset}
            >
              Request password reset
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>,
  ];
}

ForgotPassword.defaultProps = {
  open: false,
  close: () => {},
  showSnackbar: () => {},
};

ForgotPassword.propTypes = {
  open: PropTypes.any.isRequired,
  close: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
};
