import React from "react";
import PropTypes from "prop-types";
import {
  Badge,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import PeopleIcon from "@mui/icons-material/People";

export default function Menu(props) {
  const maybeClose = () => {
    if (props.closeOnInteraction) {
      props.closeMenu();
    }
  };

  const mainListItems = (
    <div>
      <ListItem
        button
        onClick={(_) => {
          maybeClose();
          props.showDashboard();
        }}
      >
        <ListItemIcon>
          <EqualizerIcon />
        </ListItemIcon>
        <ListItemText primary="Overview" />
      </ListItem>
      {props.showUsersMenuOption && (
        <ListItem
          button
          onClick={(_) => {
            maybeClose();
            props.showUsers();
          }}
        >
          <ListItemIcon>
            <Badge badgeContent={props.pendingBadgeCount} color="secondary">
              <PeopleIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
      )}
    </div>
  );

  return (
    <React.Fragment>
      <List>{mainListItems}</List>
      <Divider />
    </React.Fragment>
  );
}

Menu.defaultProps = {
  closeOnInteraction: false,
  showUsersMenuOption: false,
  showDashboard: () => {},
  showUsers: () => {},
  pendingBadgeCount: 0,
};

Menu.propTypes = {
  closeOnInteraction: PropTypes.bool.isRequired,
  showUsersMenuOption: PropTypes.bool.isRequired,
  showDashboard: PropTypes.func.isRequired,
  showUsers: PropTypes.func.isRequired,
  pendingBadgeCount: PropTypes.number.isRequired,
};
