import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

export default function Title(props) {
  return (
    <Typography
      component="h2"
      variant="h6"
      color="primary"
      className={props.className}
      gutterBottom
    >
      {props.children}
    </Typography>
  );
}

Title.defaultProps = {
  className: undefined,
};

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
