import React from "react";
import Dashboard from "./Dashboard";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { indigo, deepOrange } from '@mui/material/colors';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from "@mui/x-date-pickers";
import UserProvider from "./UserProvider";

const theme = createTheme({
  palette: {
    primary: {
      main: indigo[500],
    },
    secondary: {
      main: deepOrange[500],
    },
  },
});

function App() {
  return (
    <UserProvider>
      <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Dashboard />
        </LocalizationProvider>
      </ThemeProvider>
    </UserProvider>
  );
}

export default App;
