import React from "react";
import PropTypes from "prop-types";
import { Typography, Link } from "@mui/material";
import DateFunctions from "./DateFunctions";

export default function BodyText(props) {
  const onWantToJoin = (event) => {
    event.preventDefault();
    props.showWantToJoin();
  };

  return (
    <Typography variant="body1" gutterBottom>
      <b>PUC: 50 situps & 50 push ups a day keep the doctor away</b>
      <br />
      On popular demand, here’s the website of the Pushing Up Community (PUC):{" "}
      <Link href="#">https://arm.gasquen.com</Link>
      <p>
        Embark for this years PUC edition and enroll through the site at{" "}
        <Link href="#" onClick={(event) => onWantToJoin(event)}>
          ‘Want to join?’
        </Link>
        , starting {DateFunctions.startDate} for half a year of monitored 50
        sit-ups/50 push-ups a day training together with kindred, equally mad
        but disciplined and dedicated spirits. A daily dose would take between 4
        to 6 minutes of your time.
      </p>
      <p>
        Rules: The only payment involved in PUC is your own discipline and
        dedication to adhere to the program for half a year. PUC is not a
        competition, just a motivator. Any surpluses over the norm won’t show in
        the general charts. No need to adhere strictly to the methodology: 1 day
        unsober doing nothing, next day do 100/100 is fine. If you want to do
        nothing all week but do 350 situps and pushups on a Sunday night, then
        that’s your thing. As long as you do your 50/50 on a daily average. If
        you just want to participate doing push ups and no situps then that’s OK
        as long as you login every now and again and keep your scores. If you
        want to cheat, just wonder who you are fooling and know that we don’t
        care but for our own fulfillment of the half year schedule. And bear in
        mind, any pushup/situp technique you personally see fit or suitable is
        allowed.
      </p>
      So there it is: want to start with a freshly in-built illusion of being a
      well-disciplined individual?{" "}
      <Link href="#" onClick={(event) => onWantToJoin(event)}>
        Join PUC
      </Link>
      .
    </Typography>
  );
}

BodyText.defaultProps = {
  showWantToJoin: () => {},
};

BodyText.propTypes = {
  showWantToJoin: PropTypes.func.isRequired,
};
