import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AlertConfirmDialog from "./AlertConfirmDialog";
import Title from "./Title";

const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.down("xs")]: {
      display: "block",
      textAlign: "center",
      flex: "1 1",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flex: "1",
    },
  },
  disabled: {
    textDecoration: "line-through",
  },
}));

export default function UsersTable(props) {
  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [showContextMenu, setShowContextMenu] = useState({
    mouseX: null,
    mouseY: null,
    email: null,
  });
  const [confirmDelete, setConfirmDelete] = useState(null);

  useEffect(() => {
    firebase
      .firestore()
      .collection("users")
      .onSnapshot((snap) => {
        var newUsers = [];
        snap.forEach((snap) => {
          var email = snap.id;
          var data = snap.data();
          if (data !== undefined) {
            const user = {
              email: email,
              userinfo: data.userinfo,
            };
            newUsers.push(user);
          }
        });

        setUsers(newUsers);
      });
  }, []);

  const handleContextMenu = (event, name, email) => {
    event.preventDefault();
    setShowContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      name: name,
      email: email,
    });
  };

  const handleContextMenuClose = () => {
    // Race condition...
    setShowContextMenu({
      mouseX: null,
      mouseY: null,
      email: showContextMenu.email,
    });
  };

  const contextMenu = (
    <Menu
      id="simple-menu"
      keepMounted
      open={showContextMenu.mouseY !== null}
      anchorReference="anchorPosition"
      anchorPosition={
        showContextMenu.mouseY !== null && showContextMenu.mouseX !== null
          ? { top: showContextMenu.mouseY, left: showContextMenu.mouseX }
          : undefined
      }
      onClose={handleContextMenuClose}
    >
      <MenuItem
        onClick={() => {
          firebase.auth().sendPasswordResetEmail(showContextMenu.email);
          props.showSnackbar({
            show: true,
            message: "Password reset email sent.",
            duration: 5000,
            success: true,
          });
          handleContextMenuClose();
        }}
      >
        Send password reset email
      </MenuItem>
      <MenuItem
        onClick={() => {
          setConfirmDelete({
            name: showContextMenu.name,
            email: showContextMenu.email,
          });
          handleContextMenuClose();
        }}
      >
        Delete
      </MenuItem>
    </Menu>
  );

  const alertConfirmDialog = (
    <AlertConfirmDialog
      show={!!confirmDelete}
      title={`Delete user ${confirmDelete?.name} (${confirmDelete?.email})?`}
      body={`Do you really want to delete ${confirmDelete?.email}? All data for this user will be removed. This cannot be undone.`}
      close={() => setConfirmDelete(null)}
      onAccept={() => {
        if (!!confirmDelete && confirmDelete.email.length > 5) {
          firebase
            .firestore()
            .collection("users")
            .doc(confirmDelete.email)
            .delete();
          handleContextMenuClose();
          props.showSnackbar({
            show: true,
            message: "User deleted.",
            duration: 5000,
            success: true,
          });
        }
      }}
    />
  );

  return [
    alertConfirmDialog,
    contextMenu,
    <React.Fragment>
      <Box className={classes.box}>
        <Title>Users</Title>
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Admin</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((entry) => {
            return (
              <TableRow
                key={entry.email}
                hover
                onContextMenu={(event) => {
                  handleContextMenu(event, entry.userinfo.name, entry.email);
                }}
              >
                <TableCell>{entry.email}</TableCell>
                <TableCell>{entry.userinfo.name}</TableCell>
                <TableCell>
                  {entry.userinfo.isAdmin ? "True" : "False"}
                </TableCell>
                <TableCell align={"right"}>
                  <IconButton
                    size="small"
                    aria-label="more"
                    aria-controls="context-menu"
                    aria-haspopup="true"
                    onClick={(event) => {
                      handleContextMenu(event, entry.userinfo.name, entry.email);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </React.Fragment>,
  ];
}

UsersTable.defaultProps = {
  showSnackbar: () => {},
};

UsersTable.propTypes = {
  showSnackbar: PropTypes.func.isRequired,
};
