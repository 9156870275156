import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AlertConfirmDialog from "./AlertConfirmDialog";
import Title from "./Title";

const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.down("xs")]: {
      display: "block",
      textAlign: "center",
      flex: "1 1",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flex: "1",
    },
  },
  disabled: {
    textDecoration: "line-through",
  },
}));

export default function PendingUsersTable(props) {
  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [showContextMenu, setShowContextMenu] = useState({
    mouseX: null,
    mouseY: null,
    email: null,
    name: null,
  });
  const [confirmDelete, setConfirmDelete] = useState(null);

  useEffect(() => {
    firebase
      .firestore()
      .collection("pendingUsers")
      .onSnapshot((snap) => {
        var newUsers = [];
        snap.forEach((snap) => {
          var email = snap.id;
          var data = snap.data();
          if (data !== undefined) {
            const user = {
              email: email,
              name: data.name,
              comment: data.comment,
              date: data.date,
            };
            newUsers.push(user);
          }
        });

        setUsers(newUsers);
      });
  }, []);

  const handleContextMenu = (event, email, name) => {
    event.preventDefault();
    setShowContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      email: email,
      name: name,
    });
  };

  const handleContextMenuClose = () => {
    setShowContextMenu({
      mouseX: null,
      mouseY: null,
      email: null,
      name: null,
    });
  };

  const contextMenu = (
    <Menu
      id="simple-menu"
      keepMounted
      open={showContextMenu.mouseY !== null}
      anchorReference="anchorPosition"
      anchorPosition={
        showContextMenu.mouseY !== null && showContextMenu.mouseX !== null
          ? { top: showContextMenu.mouseY, left: showContextMenu.mouseX }
          : undefined
      }
      onClose={handleContextMenuClose}
    >
      <MenuItem
        onClick={() => {
          // Happy pathing
          firebase
            .firestore()
            .collection("newUsers")
            .doc(showContextMenu.email)
            .set({
              email: showContextMenu.email,
              name: showContextMenu.name,
              isAdmin: false,
            });

          firebase
            .firestore()
            .collection("pendingUsers")
            .doc(showContextMenu.email)
            .delete()
            .then((_) => {
              props.showSnackbar({
                show: true,
                message: "Adding user, this will be completed shortly.",
                duration: 5000,
                success: true,
              });
              handleContextMenuClose();
            });
        }}
      >
        Add user
      </MenuItem>
      <MenuItem
        onClick={() => {
          setConfirmDelete({
            email: showContextMenu.email,
          });
          props.showSnackbar({
            show: true,
            message: "User removed.",
            duration: 5000,
            success: true,
          });
          handleContextMenuClose();
        }}
      >
        Remove
      </MenuItem>
    </Menu>
  );

  const alertConfirmDialog = (
    <AlertConfirmDialog
      show={!!confirmDelete}
      title="Remove user?"
      body="Do you really want to remove this pending user?"
      close={() => setConfirmDelete(null)}
      onAccept={() => {
        if (!!confirmDelete && confirmDelete.email.length > 5) {
          firebase
            .firestore()
            .collection("pendingUsers")
            .doc(confirmDelete.email)
            .delete();
          handleContextMenuClose();
        }
      }}
    />
  );

  return [
    alertConfirmDialog,
    contextMenu,
    <React.Fragment>
      <Box className={classes.box}>
        <Title>Pending Users</Title>
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Comment</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((entry) => {
            return (
              <TableRow
                key={entry.email}
                hover
                onContextMenu={(event) => {
                  handleContextMenu(event, entry.email, entry.name);
                }}
              >
                <TableCell>{entry.email}</TableCell>
                <TableCell>{entry.name}</TableCell>
                <TableCell>{entry.date}</TableCell>
                <TableCell>{entry.comment}</TableCell>
                <TableCell align={"right"}>
                  <IconButton
                    size="small"
                    aria-label="more"
                    aria-controls="context-menu"
                    aria-haspopup="true"
                    onClick={(event) => {
                      handleContextMenu(event, entry.email, entry.name);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </React.Fragment>,
  ];
}

PendingUsersTable.defaultProps = {
  showSnackbar: () => {},
};

PendingUsersTable.propTypes = {
  showSnackbar: PropTypes.func.isRequired,
};
