import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  TextField,
} from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const useStyles = makeStyles((theme) => ({
  errorText: {
    backgroundColor: "#ff8282",
    padding: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signingIn, setSigningIn] = useState(false);
  const [lastError, setLastError] = useState("");

  const onWantToJoin = (event) => {
    event.preventDefault();
    props.close();
    props.showWantToJoin();
  };

  const onForgotPassword = (event) => {
    event.preventDefault();
    props.close();
    props.showForgotPassword();
  };

  const signIn = () => {
    setLastError("");
    setSigningIn(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        setSigningIn(false);
        props.close();
      })
      .catch((err) => {
        console.log(err);
        setSigningIn(false);
        setLastError(err.message);
      });
  };

  return [
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth="sm"
        open={props.open}
        onClose={() => {
          setLastError("");
          setSigningIn(false);
          props.close();
        }}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Sign in</DialogTitle>
        <DialogContent>
          <form className={classes.form} noValidate>
            {lastError && (
              <Box fullWidth className={classes.errorText}>
                {lastError}
              </Box>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              disabled={signingIn}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  signIn();
                }
              }}
              disabled={signingIn}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => signIn()}
              disabled={signingIn}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item>
                <Link
                  href="#"
                  variant="body2"
                  onClick={(event) => onWantToJoin(event)}
                >
                  {"Don't have an account? join PUC!"}
                </Link>
                {" - "}
                <Link
                  href="#"
                  variant="body2"
                  onClick={(event) => onForgotPassword(event)}
                >
                  {"Forgot your password?"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>,
  ];
}

SignIn.defaultProps = {
  open: false,
  close: () => {},
  showWantToJoin: () => {},
  showForgetPassword: () => {},
};

SignIn.propTypes = {
  open: PropTypes.any.isRequired,
  close: PropTypes.func.isRequired,
  showWantToJoin: PropTypes.func.isRequired,
  showForgetPassword: PropTypes.func.isRequired,
};
