import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { ResponsiveBar } from "@nivo/bar";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DateFunctions from "./DateFunctions";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const useStyles = makeStyles(() => ({
  box: {
    display: "flex",
    justifyContent: "center",
    height: 400,
  },
}));

export default function MainPlot(props) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [users, setUsers] = useState({});

  // Load all users, given the available users with data.
  useEffect(() => {
    if (users.length < 0) {
      return;
    }
    firebase
      .firestore()
      .collection("users")
      .get()
      .then((snap) => {
        var newData = [];

        const normSum = DateFunctions.normSum(new Date());
        newData.push({
          name: "Norm",
          situps: normSum,
          pushups: normSum,
        });

        snap.forEach((doc) => {
          var user = doc.id;
          var data = users[user];
          if (data !== undefined) {
            var name = doc.data().userinfo.name;
            var limit = props.noLimit ? Number.MAX_SAFE_INTEGER : normSum;
            newData.push({
              name: name,
              pushups: Math.min(limit, data.pushups),
              situps: Math.min(limit, data.situps),
            });
          }
        });

        setData(newData);
      });
  }, [users, props.noLimit]);

  // Load plot data.
  useEffect(() => {
    firebase
      .firestore()
      .collectionGroup("data")
      .onSnapshot((snap) => {
        var newUsers = {};
        snap.forEach((doc) => {
          if (DateFunctions.inHalfYear(doc.id)) {
            var user = doc.ref.parent.parent.id;
            if (newUsers[user] === undefined) {
              newUsers[user] = {};
              newUsers[user]["situps"] = 0;
              newUsers[user]["pushups"] = 0;
            }
            newUsers[user]["situps"] += doc.data().situps;
            newUsers[user]["pushups"] += doc.data().pushups;
          }
        });
        setUsers(newUsers);
      });
  }, []);

  return (
    <Box className={classes.box}>
      <ResponsiveBar
        groupMode="grouped"
        data={data}
        keys={["situps", "pushups"]}
        indexBy="name"
        margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
        padding={0.3}
        colors={{ scheme: "set2" }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        enableLabel={false}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Sit-ups",
          legendPosition: "middle",
          legendOffset: -50,
        }}
        axisRight={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Push-ups",
          legendPosition: "middle",
          legendOffset: 50,
        }}
      />
    </Box>
  );
}

MainPlot.defaultProps = {
  noLimit: false,
};

MainPlot.propTypes = {
  noLimit: PropTypes.bool.isRequired,
};
