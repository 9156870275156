import React, { useEffect, useState, createContext } from "react";
import PropTypes from "prop-types";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);

export const UserContext = createContext(null);
export default function UserProvider(props) {
  const [state, setState] = useState(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((userAuth) => {
      if (!!userAuth) {
        const email = firebase.auth().currentUser.email;
        setState({ email: email });

        // Fetch additional user metadata
        firebase
          .firestore()
          .collection("users")
          .doc(email)
          .get()
          .then((snap) => {
            const data = snap.data();
            if (data.userinfo) {
              const userinfo = data.userinfo;
              setState({
                email: email,
                isAdmin: userinfo.isAdmin,
                name: userinfo.name,
              });
            }
          })
          .catch((err) => {});
      } else {
        setState(null);
      }
    });
  }, []);

  return (
    <UserContext.Provider value={state}>{props.children}</UserContext.Provider>
  );
}

UserProvider.defaultProps = {
  className: undefined,
};

UserProvider.propTypes = {
  children: PropTypes.node,
};
