import React from "react";
import PropTypes from "prop-types";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/lab";

export default function ConvenientSnackbar(props) {
  const handleSnackbarClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    // Snackbar shown twice. Race condition on close?
    // Or rather, content snackbar is changed, but its not closed
    // directly. Set same content on close..
    props.setShowSnackbar({
      show: false,
      message: props.message,
      success: props.success,
      duration: 1,
    });
  };

  return (
    <Snackbar
      open={props.show}
      autoHideDuration={props.duration}
      onClose={handleSnackbarClose}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={props.success ? "success" : "error"}
      >
        {props.message
          ? props.message
          : props.success
          ? "OK!"
          : "Something went wrong."}
      </Alert>
    </Snackbar>
  );
}

ConvenientSnackbar.defaultProps = {
  show: false,
  duration: 2000,
  success: true,
  closeSnackbar: () => {},
};

ConvenientSnackbar.propTypes = {
  show: PropTypes.bool.isRequired,
  setShowSnackbar: PropTypes.func.isRequired,
  message: PropTypes.string,
  duration: PropTypes.number,
  success: PropTypes.bool,
};
