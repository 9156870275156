import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
  },
}));

export default function AppBarTitle(props) {
  const classes = useStyles();

  return (
    <Typography
      component="h1"
      variant="h6"
      color="inherit"
      noWrap
      className={classes.title}
    >
      {props.children}
    </Typography>
  );
}

AppBarTitle.propTypes = {
  children: PropTypes.node,
};
