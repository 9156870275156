import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";

const useStyles = makeStyles((theme) => ({
  errorText: {
    backgroundColor: "#ff8282",
    padding: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AddUser(props) {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [creating, setIsCreating] = useState(false);
  const [lastError, setLastError] = useState("");

  const validEmail = email.length > 5;
  const validName = name.length >= 4;
  const allValid = validEmail && validName;

  const createUser = () => {
    if (!allValid) {
    }

    setLastError("");
    setIsCreating(true);

    firebase
      .firestore()
      .collection("newUsers")
      .doc(email)
      .set({
        email: email,
        name: name,
        isAdmin: isAdmin,
      })
      .then(() => {
        setIsCreating(false);
        props.showSnackbar({
          show: true,
          message: "Adding user, this will be completed shortly.",
          duration: 5000,
          success: true,
        });
        props.close();
      })
      .catch((err) => {
        console.log(err);
        setIsCreating(false);
        setLastError(err.message);
      });
  };

  return [
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth="sm"
        open={props.open}
        onClose={() => {
          setLastError("");
          setIsCreating(false);
          props.close();
        }}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <Typography component="h1" variant="h5">
            Add User
          </Typography>
          <form className={classes.form} noValidate>
            {lastError && (
              <Box fullWidth className={classes.errorText}>
                {lastError}
              </Box>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              error={!validEmail}
              helperText={!validEmail ? "Valid email is required." : ""}
              autoComplete="email"
              autoFocus
              onChange={(event) => {
                setEmail(event.target.value.trim());
              }}
              disabled={creating}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              error={!validName}
              helperText={!validName ? "Att least 4 characters." : ""}
              autoComplete="name"
              onChange={(event) => {
                setName(event.target.value.trim());
              }}
              disabled={creating}
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="admin"
                  color="primary"
                  onChange={(event) => setIsAdmin(event.target.checked)}
                />
              }
              label="Administrator"
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => createUser()}
              disabled={creating || !allValid}
            >
              Add User
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>,
  ];
}

AddUser.defaultProps = {
  open: false,
  close: () => {},
  showSnackbar: () => {},
};

AddUser.propTypes = {
  open: PropTypes.any.isRequired,
  close: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
};
