import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ResponsiveLine } from "@nivo/line";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DateFunctions from "./DateFunctions";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const useStyles = makeStyles(() => ({
  box: {
    display: "flex",
    justifyContent: "center",
    height: 400,
  },
}));

export default function UserPlot(props) {
  const classes = useStyles();

  const [data, setData] = useState([]);

  // Load plot data.
  useEffect(() => {
    firebase
      .firestore()
      .collection("users")
      .doc(props.user)
      .collection("data")
      .onSnapshot((snap) => {
        var newData = [];
        var situps = [];
        var pushups = [];
        var situpsTotal = 0;
        var pushupsTotal = 0;

        // Get current values.
        snap.forEach((doc) => {
          if (DateFunctions.inHalfYear(doc.id)) {
            situpsTotal += doc.data().situps;
            pushupsTotal += doc.data().pushups;
            situps[doc.id] = situpsTotal;
            pushups[doc.id] = pushupsTotal;
          }
        });

        // Fill in gaps.
        var range = DateFunctions.getRangeToNow();
        var previousSitups = 0;
        var previousPushups = 0;
        var situpsPlotData = [];
        var pushupsPlotData = [];
        var normPlotData = [];

        range.forEach((date) => {
          if (situps[date] === undefined) {
            situps[date] = previousSitups;
            pushups[date] = previousPushups;
          }
          previousSitups = situps[date];
          previousPushups = pushups[date];

          normPlotData.push({
            x: date,
            y: DateFunctions.normSumForDate(date),
          });
          situpsPlotData.push({ x: date, y: previousSitups });
          pushupsPlotData.push({ x: date, y: previousPushups });
        });

        newData.push({ id: "Norm", data: normPlotData });
        newData.push({ id: "Sit-ups", data: situpsPlotData });
        newData.push({ id: "Push-ups", data: pushupsPlotData });
        setData(newData);
      });
  }, [props.user]);

  return (
    <Box className={classes.box}>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 100, bottom: 90, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "50",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 90,
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "count",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        useMesh={true}
        colors={{ scheme: "set1" }}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
          },
        ]}
      ></ResponsiveLine>
    </Box>
  );
}

UserPlot.defaultProps = {
  user: null,
};

UserPlot.propTypes = {
  user: PropTypes.string.isRequired,
};
