import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { format } from "date-fns";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const useStyles = makeStyles((theme) => ({
  form: {
    flexGrow: 1,
  },
  formControl: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    whiteSpace: "nowrap",
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  gridItem: {
    width: "fit-content",
  },
  label: {
    marginTop: theme.spacing(3),
    minWidth: 120,
  },
  confirm: {
    backgroundColor: "#ff8282",
  },
}));

export default function EntryDialog(props) {
  const classes = useStyles();

  const [pushups, setPushups] = useState(0);
  const [situps, setSitups] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Valid state / validators
  // eslint-disable-next-line
  const validDate = !!selectedDate && selectedDate != "Invalid Date";

  const updateEntry = () => {
    if (!validDate) {
      return;
    }
    if (!props.user) {
      return;
    }

    firebase
      .firestore()
      .collection("users")
      .doc(props.user)
      .collection("data")
      .doc(format(selectedDate, "Y-MM-dd"))
      .set({
        situps: isNaN(situps) ? 0 : situps,
        pushups: isNaN(pushups) ? 0 : pushups,
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  };

  const setDefault = () => {
    setSitups(0);
    setPushups(0);
  };

  // Load in edit view
  useEffect(() => {
    if (!validDate) {
      return;
    }
    if (!props.user) {
      return;
    }

    firebase
      .firestore()
      .collection("users")
      .doc(props.user)
      .collection("data")
      .doc(format(selectedDate, "Y-MM-dd"))
      .get()
      .then((querySnapshot) => {
        var data = querySnapshot.data();
        setSitups(data.situps);
        setPushups(data.pushups);
      })
      .catch(function (error) {
        setDefault();
        console.error("Error reading: ", error);
      });
  }, [selectedDate, validDate, props.user]);

  return [
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth="sm"
        open={props.open}
        onClose={() => {
          updateEntry();
          props.close();
        }}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Add or edit workout
        </DialogTitle>
        <DialogContent>
          <form className={classes.form} noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <DatePicker
                    slotProps={{ textField: { variant: 'standard', } }}
                    autoFocus
                    format="yyyy-MM-dd"
                    margin="dense"
                    id="time-picker"
                    label="Select Date"
                    value={selectedDate}
                    onChange={(date, _) => {
                      setSelectedDate(date);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant='standard'
                  className={classes.formControl}
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 999,
                      min: 0,
                    },
                  }}
                  id="pushups"
                  label="Push-ups"
                  required={false}
                  value={pushups}
                  onChange={(event) => {
                    setPushups(parseInt(event.target.value));
                    updateEntry();
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant='standard'
                  className={classes.formControl}
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 999,
                      min: 0,
                    },
                  }}
                  id="situps"
                  label="Sit-ups"
                  required={false}
                  value={situps}
                  onChange={(event) => {
                    setSitups(parseInt(event.target.value));
                    updateEntry();
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              updateEntry();
              props.close();
            }}
            color="primary"
            disabled={!validDate}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>,
  ];
}

EntryDialog.defaultProps = {
  open: false,
  user: null,
  close: () => {},
};

EntryDialog.propTypes = {
  open: PropTypes.any.isRequired,
  user: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};
